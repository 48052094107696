<script setup lang="ts">
import CountDown from '@/components/ToastMessages/CountDown.vue';
import { initToastMessageSubscription, ToastMessagesMap } from '@/components/ToastMessages/toast-messages';
import { MESSAGE_TYPE } from '@/shared/message-service';
import { onMounted, onUnmounted, ref } from 'vue';

const toastMessages = ref<ToastMessagesMap>(new Map());

let messageSubscription: { unsubscribe: () => void };

onMounted(() => {
  messageSubscription = initToastMessageSubscription(toastMessages.value);
});

onUnmounted(() => {
  messageSubscription.unsubscribe();
});

function dismissMessage(messageId: string) {
  toastMessages.value.delete(messageId);
}
</script>

<template>
  <aside class="toastmessages-list">
    <v-card
      v-for="[, value] in toastMessages"
      :key="value.id"
      class="ma-2"
      :class="`messagetype ${value.type}`"
      :title="value.title"
      elevation="0"
    >
      <template #prepend>
        <v-icon
          :color="value.type"
          :icon="`mdi-${value.type === MESSAGE_TYPE.SUCCESS ? 'check' : value.type === MESSAGE_TYPE.ERROR ? 'close-circle' : value.type === MESSAGE_TYPE.WARNING ? 'alert-outline' : 'information-outline'}`"
        ></v-icon>
      </template>
      <template #append>
        <CountDown :message="value" @click="dismissMessage(value.id)" />
      </template>
      <v-card-text class="toastmessage-list-item">
        <span v-html="value.text" />
      </v-card-text>
    </v-card>
  </aside>
</template>

<style lang="scss" scoped>
.toastmessages-list {
  position: fixed;
  left: 24px;
  bottom: 24px;
  z-index: 100;
  max-width: calc(100% - 24px - 24px);
  width: 503px;
}

.toastmessage-list-item {
  align-items: start;
  flex-direction: column;
  padding: 0 3.5rem 1rem;
}

.messagetype {
  margin: 10px 0 0 0 !important;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;

  &.warning {
    border-color: $item_orange;
    background-color: $item_orange_light;
  }
  &.error {
    border-color: $item_red;
    background-color: $item_red_light;
  }
  &.success {
    border-color: $item_green;
    background-color: $item_green_light;
  }
  &.info {
    border-color: $item_grey;
    background-color: $item_grey_light;
  }
}
</style>
